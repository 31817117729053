<template>
  <div class="fixed pxWxDisable">
    <div class="top">
      <img class="t1" src="../../assets/img/1.1.5/ico_gth@2x.png" />
      <div class="t2">
        请使用电脑浏览器打开链接进入
      </div>
      <div class="t3">
        https://teacher.dingdingkaike.com
      </div>
      <button class="t4 copy-btn" data-clipboard-text="https://teacher.dingdingkaike.com">
        复制链接
      </button>
    </div>
    <div class="bottom">
      <div class="t1">推荐使用谷歌浏览器，兼容性最好</div>
      <img class="t2" src="../../assets/img/1.1.5/img_ggllq@2x.png" />
      <div class="t3">Google Chrome 浏览器</div>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard'

export default {
  name: 'pxWxDisable',

  mounted() {
    this.clipboardInit()
  },

  methods: {
    // 复制
    clipboardInit() {
      const self = this
      const clipboard = new Clipboard('.copy-btn')
      clipboard.on('success', function() {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success'
        })
      })
      clipboard.on('error', function() {
        self.$root.prompt('复制失败')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pxWxDisable {
  text-align: center;
  z-index: 999;
  background: #fff;
  .top {
    position: absolute;
    left: 50%;
    top: 20%;
    width: 300px;
    transform: translateX(-50%);
    .t1 {
      width: 48px;
      height: 48px;
      margin: 0 auto;
    }
    .t2 {
      font-size: 17px;
      font-weight: 400;
      margin-top: 28px;
      color: rgba(51, 51, 51, 1);
      line-height: 28px;
    }
    .t3 {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin-top: 20px;
      color: rgba(51, 51, 51, 1);
    }
    .t4 {
      border: 0;
      display: block;
      width: 200px;
      margin: 0 auto;
      font-size: 14px;
      border-radius: 4px;
      line-height: 19px;
      margin-top: 28px;
      cursor: pointer;
      line-height: 36px;
      color: rgba(255, 255, 255, 1);
      background: rgba(10, 162, 155, 1);
    }
  }
  .bottom {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 300px;
    transform: translateX(-50%);
    .t1 {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(102, 102, 102, 1);
    }
    .t2 {
      width: 28px;
      height: 28px;
      margin: 0 auto;
      margin-top: 50px;
    }
    .t3 {
      margin-top: 6px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(51, 51, 51, 1);
    }
  }
}
</style>
